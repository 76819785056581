@import "../../../general";


  a{
    text-decoration: none;
    color: black;

  }
  a:visited{
    text-decoration: none;
    color: black;
  }


  .service_slide__preview{
    min-width: 100px;
    width: 70%;
    min-height: 160px;
    margin: 30px auto;
    padding: 1em;
    cursor: pointer;
    transition: all .3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.19), 0 2px 6px rgba(0, 0, 0, 0.23);

    @include breakpoint(mobile){
      min-height: 100px;
      padding: 10px;
      transform: scale(1.1);
    }

    &:focus{
      outline: none;
    }
    @include breakpoint(laptop) {
      &:hover {
       transform: scale(0.9)
    }
}

    .preview__icon{
      margin:auto;
      width: 40%;

    }
    .preview__title{
      text-align: center;
      margin:auto;
      font-size: 1em;
      @include breakpoint(mobile){
        font-size: 0.5rem;

      }
      a{
        text-decoration: none;
        color: black;

      }
      a:visited{
        text-decoration: none;
        color: black;
      }
    }





}

@import "../../general";

.footer {
  margin-top: 5em;
  height: auto;
  overflow: visible;
  padding-top:100px;

  @include breakpoint(mobile){
    margin-top: 52em;

  }

  .footer__back-section{
    height: 557px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    overflow: visible;
    @include breakpoint(mobile){
      height: 26rem;
    }

  }


  .back-section__message-us {
    margin-right: 1.5em;
    text-align: right;
    margin-top: -6.5em;
    margin-left: auto;

    @include breakpoint(mobile){
      display:none;
    }

    h2:last-of-type{
      color: $primary;
    }
  }

   .footer__contact-info{

     display:flex;
     min-height: 120px;
     width: 100%;
     background-color: $primary;
     justify-content: space-around;
     color: white;


     div{

         margin: auto;


     }

      @include breakpoint(mobile){
        p:nth-child(odd){
          margin-bottom: 5px;
          margin-top: 20px;

        }
        p:nth-child(even){
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      .contact-info__contacts{
        display: none;
        @include breakpoint(mobile) {
         display:block;
         font-size: 1.5rem;
         width: 80%;
         margin: 1.4em auto 0em auto;



        }

      }


     @include breakpoint(mobile){
       flex-direction: column;
       width: 80%;
       margin: -33rem auto auto;
       padding-bottom: 2.4em;
       div{
        width:80%;
       }
       p{
         color: white;
       }
     }
   }




}

.footer--v2{

  order: 4;
  margin-top: 73px;
  overflow: visible;
  padding-top:0;
  display: flex;
  flex-direction: column-reverse;

  @include breakpoint(mobile){
   margin-top: 480px;

  }
  .footer--v2__back-section--v2{
    height: 300px;
    width:100%;
  }
  .footer--v2__contact-info--v2{
    width: 61%;
    min-width: 584px;
    margin-bottom: -65px;

    @include breakpoint(mobile){
      margin-bottom: -175px;
      width: 80vw;
      min-width: unset;

   }
  }
}

$primary: #587993;//#014656;
$secondary:#E2C43D;
$text-color:#474747;
$grey:#ECECEB;





/*********************
BREAKPOINTS
*********************/

@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 1300px) { @content ; }
  }
  @else if $point == laptop {
    @media (min-width: 1113px) { @content ; }
  }
  @else if $point == tablet {
    @media (max-width: 1112px)  { @content ; }
  }
  @else if $point == mobile {
    @media (max-width: 700px)  { @content ; }

  }

}


table{
  text-align: -webkit-match-parent;
  width: 100%;

  th{
    height: 40px;

  }
  td{
    height: 25px;

  }
}
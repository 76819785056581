@import "../../general";

.slick-prev, .slick-next{
  width: 50px;
  height: 50px;

  @include breakpoint(mobile){
    width: 25px;
    height: 25px;


  }
}
.slick-prev{
  z-index: 2;
}

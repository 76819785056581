@import "../../../general";
.ba-slide{
  display: grid !important;
  grid-template-columns: 300px 300px;
  grid-template-rows: 20px auto;
  grid-gap: 10px;
  max-width: max-content;
  margin: 20px auto;
  justify-items: center;




  @include breakpoint(mobile){
    grid-template-rows: 30px auto 30px auto;
    padding: 2em 0 0 0;
    grid-template-columns: 200px;
    justify-content: center;

  }
.ba-slide__before-img{
  order:3;

  @include breakpoint(tablet){
     order:3;
  }
}
  .ba-slide__after-img{
    order:4;
    @include breakpoint(tablet){
      order:4;
    }
  }




  label{
    justify-self: flex-start;
    margin-left: 1.3em;
    @include breakpoint(mobile){
     margin-left: 0;

    }


  }
  .ba-slide__after-img, .ba-slide__before-img{
    width:250px;
    height: auto;

    @include breakpoint(mobile){
      order: unset;
      width:200px;
      height:auto;

    }
  }
}

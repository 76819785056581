@import "../../general";

.contact-form {
  width: 38%;
  height: 100%;
  margin-top: -6.4em;
  margin-right: 14%;
  z-index: 1;
  background-color: whitesmoke;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px 10px;

  @include breakpoint(mobile) {
    padding: 50px 0;
    height: unset;
  }


  label {
    font-size: 1.1rem;
    padding-bottom: 10px;
  }

  @include breakpoint(mobile) {
    width: 100%;
    margin: -54rem auto auto;
  }


  .contact-form__contact-us {
    display: none;

    @include breakpoint(mobile) {
      display: block;
      margin-right: auto;
      font-size: 1.4rem;
      color: $text-color;
      margin-left: 8.5%;
      margin-bottom: 0.2em;
    }
  }

  .contact-form__line {
    display: none;

    @include breakpoint(mobile) {
      display: block;
      height: 2px;
      background-color: $primary;
      width: 14.2%;
      margin-right: auto;
      margin-left: 9%;
      margin-bottom: 1.875rem;

    }
  }


  .contact-form__message {
    display: flex;
    flex-direction: column;
    width: 82.4%;
    margin-bottom: 1.875rem;

    textarea {
      height: 90px;
      border: none;
      border-radius: 5px;
      padding: 5px;

    }

    textarea:focus {
      outline: none;

    }
  }


  .contact-form__send {
    width: 187px;
    background: $primary;
    border: none;
    color: white;
    font-size: 1rem;
    height: 44px;
    margin: 1.4em auto 0 9.5%;
    cursor: pointer;

  }

  .contact-form__send:hover {
    background: $primary;
  }

  .contact-form__send:active {
    background-color: $primary;

    transform: translateY(2px);
  }
}

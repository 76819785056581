@import "../../../general";
.doctor-slide{
  margin: auto;
  width: 80%;

  display: flex;
  flex-direction: column;

  .doctor-slide__fill{
    background: $grey;

    height: 224px;
  }
  .doctor-slide__doc-name{

    text-align: center;
    font-size: 1.2rem;
    margin: 1rem auto;
    color: $primary;
  }

}

@import "../../general";
.btn--primary{
  background-color: $secondary;
  color: whitesmoke;
  text-align: center;
  width: 46%;
  height: 53px;
  border: none;
  box-shadow: none;
  font-size: 1rem;
  min-width: max-content;
  padding: 15px;
}
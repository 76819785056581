@import "../../general";

.custom-input{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 82%;

 .custom-input__input{
   height: 40px;
   border: none;
   padding: 5px;
   border-radius: 4px;
 }

  .custom-input__input:focus{
    outline:none;

  }
}

custom-input__label{
  font-size: 18px;
}


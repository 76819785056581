@import "../../general";
.qa{

  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1.3rem 0;
  cursor: pointer;
  color: $text-color;

  @include breakpoint(mobile){
    margin: 0.2em auto 1.7em;
    width: 80%;
  }

  .qa__expanded{
    padding-bottom: 6px;
    display: flex;
    @include breakpoint(mobile){
      padding-bottom: 0;
    }

  }
  .expanded__question{

    font-size:1.2rem;
    font-weight: 600;
    @include breakpoint(mobile){
      font-size: 0.8rem;
      font-weight: 600;

    }

  }

  .qa__line{
    height: 0.7px;
    width: 100%;
    background-color: #C4C4C4;
  }

  .qa__answer{
    margin: 40px auto auto;
    padding:20px;
    border: 1px solid #C4C4C4;
    width: 98%;
  }
}
 .question__icon{
  margin-left: auto;
  height: 30px;
  width:30px;

  font-weight: 900;
  color: $text-color;
  float: right;
  cursor: pointer;

   @include breakpoint(mobile){
     height: 22px;
     width: 22px;
     padding-bottom: 5px;
   }

}

 .icon--minus{
   width: 19px;
   margin-right: 5px;
 }

.qa__answer{
  transition: display 2s ease-in-out;
}
@import "../../general";
.services{
  .services__banner{
    background: no-repeat center center;
    background-size: cover;
    height: 30em;
    display: flex;
    margin-bottom: 3.5em;

    @include breakpoint(mobile){
      height:20em;
      margin-bottom: 2em;
    }
    .services__title{
      font-size: 1.5rem;
      line-height: 3rem;
      width: 26%;
      min-width: 200px;
      color: white;
      margin: auto auto 0 0;
      background-color: #608199;
      display: flex;
      justify-content: center;

      @include breakpoint(mobile){
        font-size: 1.3rem;
      }


      span{
        margin:  0.6em;
        height: max-content;
      }
    }
  }





}

@import "../../general";
.home{
   flex-direction: column;
    padding-top: 30px;


  @include breakpoint(mobile){
   padding-top: 0;
 }
  .home__articles{
    width:90%;
    margin:auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;

  @include breakpoint(mobile){
      margin: 1em auto 4em;
      padding-top:0;
      grid-template-columns: 100%;
      grid-row-gap: 1em;

    }
    .one{

      @include breakpoint(tablet){
        grid-column: 1;
        grid-row: 2;
      }

    }

    .two{
      grid-column: 1;
      grid-row: 1/6;
      display: flex;
      overflow: visible;

      @include breakpoint(tablet){
        height:170px;
      }

      img{
        width: 100%;
        height: 100%;

        @include breakpoint(tablet){
          width:50%;
          height: 100%;

        }
      }
      @include breakpoint(tablet){
        grid-row: 1;
      }

     p {
       color: $text-color;
       @include breakpoint(laptop) {
         opacity: 0;
         transition: opacity 2s ease-in-out;
         z-index: 3;
         width: 100%;
         text-align: center;
         padding: 22px 0 60px;
         vertical-align: baseline;
         margin: 0 0 0 -100%;
         background: rgba(250,250,250,0.5);
       }
     }
       &:hover{
         @include breakpoint(laptop){
           p{
             opacity: 1;
           }}
       }

    }

    .three {
      grid-row-end: 4;
      @include breakpoint(tablet){
        grid-column: 2;
        grid-row: 1;
      }
    }

    .four{
      grid-row-end: 6;
      @include breakpoint(tablet){
        grid-column: 2;
        grid-row: 2;
      }
    }


    .one, .three, .four {
      display: flex;
      height:170px;
      img{
        width: 36%;
        height: 100%;

        @include breakpoint(tablet){
          width: 50%;
          height: 100%;
        }


      }
      @include breakpoint(laptop){
        grid-column: 2;
      }
      @include breakpoint(mobile){
        grid-column: 1;
        grid-row: unset;
      }

  }

    .one, .two, .three, .four{

      cursor:pointer;
    }
  p{
    margin:auto auto auto 30px;
    line-height: 1.5;
   }
  }
}


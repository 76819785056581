@import "../../general";

.service{
  display:flex;
  flex-direction: column;



  .service > div {
    @include breakpoint(mobile) {
      :nth-child(1) {
        order: 1;
      }
      :nth-child(2) {
        order: 3;
      }
      :nth-child(3) {
        order: 2;
      }
    }
  }

  .service__info-section{
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: max-content;
    margin-bottom: 6.25em;

    @include breakpoint(mobile){
      margin-bottom: 0;
      flex-direction: column;
    }

    .info-section__cover-img {
      border-style: none;
      height: auto;
      min-height: 24vh;
      width: 45%;
      margin: 4.125em 0 0 5.25em;
      box-shadow: -5.35em -5.8em 0px 0.6em $secondary;
      background-color: $primary;
      @media screen and (min-width: 700px) and (max-width:800px) {
        width: 65%;
        margin: 3.125em 0 0 1em;

      }
      @include breakpoint(mobile){
        min-height: 0;
        width: 90%;
        height: 0;
        margin: 0;
        box-shadow: -6.2em -0.8em 0px 0.8em $secondary;
      }
   }

    .info-section__details {
      color:$text-color;
      display: flex;
      flex-direction: column;
      margin-top: 5em;
      margin-right: auto;
      margin-left: 5em;
      max-width: 35%;
      justify-content: center;

      @include breakpoint(mobile){
        max-width: 80%;
        margin: 20px 10% 0;


      }
      @media screen and (min-width: 700px) and (max-width:800px) {

        margin-left:2em;

      }
     .details__attributes{
       font-size: 0.9rem;
       font-weight: 600;
       order:unset!important;

     }
      p{
        font-size: 0.8rem;
        margin-top: 0.4rem;
      }
      .details__title {
        font-size: 1.8rem;
        display: block;
        margin-bottom: 0.2em;

        @include breakpoint(tablet){
          font-size: 1.5rem;
          margin-bottom: 0.5em;

        }

        @include breakpoint(mobile){
          font-size: 1.3rem;
          margin-bottom: 0.8em;

        }

      }
    }
  }
  .service__qa-section{
    display:flex;
    flex-direction: column;
    width: 83%;
    margin: auto auto 20px;

    @include breakpoint(mobile){
      order: 2;
      margin: 1px 0;
      width: 100%;
    }

  }


.service__slider-section{
  @include breakpoint(mobile){
    order: 3;
    width:100%;
    margin: 2em 0 0 0;
  }
  margin: auto auto 100px;
  width: 90%;


  .slider{

  background-color: $grey;
  .slick-prev {
    left: 6vw;

  }




  .slick-next, .slick-prev{
    width: 90px;
    height: 90px;
    top:50%;

    @include breakpoint(mobile){
      width: 50px;
      height: 50px;
      top:50%;
    }
  }

  .slick-next{
    right: 6vw;

  }

    @media screen and (min-width: 700px) and (max-width:750px) {
     .slick-next {
         right: -1vw;
       width: 50px;
      }
      .slick-prev{
        left: -1vw;
        width: 50px;
      }
    }
    @media screen and (min-width: 751px) and (max-width:950px) {
      .slick-next {
        right: 2vw;
        width: 70px;
      }
      .slick-prev{
        left: 2vw;
        width: 70px;
      }
    }
    @media screen and (min-width: 300px) and (max-width:350px) {
      .slick-next {
        right: -3vw;
        width: 70px;
      }
      .slick-prev{
        left: -3vw;
        width: 70px;
      }
    }





  }
}}

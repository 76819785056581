@import "../../general";

.header {
    max-width: inherit;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 20;
    position: fixed;
    background-color: white;
    text-decoration: none;

    @include breakpoint(mobile){

        flex-direction: column;
        padding: 10px 0 0;
    }


    a{
        text-decoration:none ;
    }
    .header__hide {
        margin: auto 1.3em;
        display: flex;
        justify-content: center;
        text-decoration: none;
        justify-self: right;



    .hide__option {
        min-width: fit-content ;
        font-size: 1.3em;
        margin: 0px 1em;
        transition: color 1.5s;
        cursor: pointer;
        color: $text-color;

        @include breakpoint(mobile) {
            display: none;
        }
     }
    }



    .toggle__logo{
        text-decoration: none;
        color: $text-color;
        margin-left: 1em;
        display: flex;
        min-width: fit-content;
        cursor: pointer;

         @include breakpoint(mobile){
           margin-right: 1rem;
         }

        .logo__primary {
            border-right: 1px solid;
            padding-right: 5px;
            margin-right: 5px;
            font-size: 2.2rem;
            font-weight: 900;
            min-width: 160px;
            justify-self: left;
            font-family: "Times New Roman", serif;

            @include breakpoint(mobile){
                font-size: 2rem;
            }
        }

        .logo__secondary{

            font-size: 0.9rem;
            margin: auto 0 auto 0.2rem;

            max-width: 128px;

            @include breakpoint(mobile){
                font-size: 0.6rem;
            }


        }



    }
    //////////////////////Dropdown////////////////

    .header__toggle{
        display: flex;
    }

    .toggle__burger{
        display: none;
        @include breakpoint(mobile){
            display: block;
            margin-left:auto;
            margin-right: 1em;
        }
    }


    .header__show{
        @include breakpoint(mobile) {
        flex-direction: column;
        padding: 15px 15px 0;
        .show__option{
            display: block;
            height: auto;
            padding: 0.5em 0.5em 0.5em 0px;
            margin: 0;
            border-bottom: 1px solid lightgrey;

        }
      }
    }

}







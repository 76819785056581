@import "../../general";



.about{
  color: $text-color;
  display: flex;
  flex-direction: column;

   .about__description{
     display:flex;
     height: auto;
     justify-content: space-between;

     @include breakpoint(tablet){
       flex-direction: column;
     }

     .about__img{
       width: 46%;
       height:20%;
       object-fit: cover;

       @include breakpoint(tablet){
         width:100%;
       }
     }
     .description__text{
       display: flex;
       flex-direction: column;
       width: 42%;
       margin: auto;


       @include breakpoint(tablet){
         width: 80%;
         margin: 20px auto;
       }

     p{
     font-size: 1.1rem;
     margin-bottom: 2em;
       line-height: 1.5em;

       @include breakpoint(mobile){
         margin-top: 1em;
         margin-bottom: 1em;
       }
     }
     .text__about-us{
       font-size: 2.1rem;
       @include breakpoint(mobile){
         margin-top: 0.3em;
       }
      }
     }
   }


  .about__doctor-slider{
    margin-top: 5.6em;
    display:flex;
   flex-direction: column;

    @include breakpoint(mobile){

      margin-top:4em;
    }

     .doctor-slider__title{
       margin: 1.4em auto;
       font-size: 2.4rem;

       @include breakpoint(mobile){
          font-size: 1.4rem;
        }
     }

  .slick-track:focus{
    outline: none;
    }
  }
}

div.doctor-container > div > div > div > div > div > div :focus{
  outline: none;

}
.doctor-slider__slides{
  .slick-next, .slick-prev {
    top: 36%;
    width: 50px;
    height: 50px;
    @include breakpoint(mobile){
      width: 25px;
      height: 25px;
    }
  }

  .slick-next{
    right:-5vw;
  }

  .slick-prev{
    left: -5vw;
  }
  width: 86%;
  margin:  auto;

}
